import { graphql } from 'gatsby';
import Link from '@components/Link';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Footer from '@components/Footer/Footer';
import { useLocale } from '@hooks';
import Header from '@components/Header/Header';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import { getDataByLang } from '@helpers/GlobalHelpers';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyApiDe {
            getMarkdownContent(
                input: { key: "SIGN_UP_REQUIREMENTS", locale: GERMAN }
            ) {
                content {
                    content
                }
            }
        }
        aepsyApiEn {
            getMarkdownContent(
                input: { key: "SIGN_UP_REQUIREMENTS", locale: ENGLISH }
            ) {
                content {
                    content
                }
            }
        }
        aepsyApiFr {
            getMarkdownContent(
                input: { key: "SIGN_UP_REQUIREMENTS", locale: FRENCH }
            ) {
                content {
                    content
                }
            }
        }
        aepsyApiIt {
            getMarkdownContent(
                input: { key: "SIGN_UP_REQUIREMENTS", locale: ITALIAN }
            ) {
                content {
                    content
                }
            }
        }
    }
`;

const SignupRequirementsPage = ({ data }: any) => {
    const { t } = useTranslation();
    const { currentLanguage } = useLocale();

    const markdownContentRes = getDataByLang({
        currentLanguage,
        key: 'getMarkdownContent',
        data,
    });

    const renderContent = () => {
        if (
            !markdownContentRes ||
            (markdownContentRes && !markdownContentRes.content)
        )
            return <></>;
        const data = markdownContentRes.content[0];
        if (!data) return <></>;

        return <MarkdownText content={data.content} />;
    };

    return (
        <Layout>
            <Seo title={t('sign.up.requirements.title')} />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="dark">
                    <Header
                        theme="dark"
                        title={t('sign.up.requirements.title')}
                        align="center"
                        spacing="s"
                        size="thin"
                    />
                </Section>

                <Section theme="light" spacing="m" container="large">
                    {renderContent()}

                    <Section spacing="m">
                        <Link to="/">
                            <Button label={t('button.back')} />
                        </Link>
                    </Section>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(SignupRequirementsPage);
